import Link from 'next/link';
import { clsx } from 'clsx';
import { ButtonPrimary, Card, Flex } from 'moralis-ui';
import { CoinLogo } from '@/components/common/CoinLogo';
import { TimeInterval } from '@/helpers/hooks';
import { utils } from '@/helpers/utils';
import { ERoutePath } from '@/helpers/utils/constants';
import { CoinFilterDto, CoinInfoDto } from '@/services/index';
import { computeHypeScore } from '@/utils/hypeScore';
import { getCoinURLById } from '@/utils/urls';
import { faSliders } from '@fortawesome/pro-regular-svg-icons';
import { faFireFlameCurved } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getCategoryID } from './utils/getCategoryID';
import { getTokenExplorerLinkFilters } from './utils/getTokenExplorerLinkFilters';
import { OPTIONS_OF_TRENDING } from './utils/optionsSelector';
import styles from './EmptyBox.module.scss';

const COMPUTED_HYPE_SCORE_MIN = 50; // minimum value for color change

export const EmptyBoxWrapper = ({ children }: React.PropsWithChildren) => (
  <div className={styles.emptyBox}>{children}</div>
);

export const EmptyBoxTitle = ({
  categoryTitle,
  chainTitle,
}: {
  categoryTitle: string | undefined;
  chainTitle: string | undefined;
}) =>
  categoryTitle || chainTitle ? (
    <div className={styles.emptyBoxTitle}>
      There are no trending {categoryTitle} tokens {chainTitle} right now
    </div>
  ) : null;

export const EmptyBoxTitleCollection = ({
  collectionTitle,
  chainId,
}: {
  collectionTitle: string | undefined;
  chainId: string | null;
}) => {
  return (
    <div className={styles.emptyBoxTitleCollection}>
      {chainId !== null && <>or</>} {collectionTitle}
    </div>
  );
};

export const EmptyBoxTokenExplorerLink = ({
  chainId,
  category,
  categoryTitle,
  chainTitle,
  tokensCount,
}: {
  chainId: string | null;
  category: string | null;
  categoryTitle: string | undefined;
  chainTitle: string | undefined;
  tokensCount: string | undefined;
}) => {
  // check if defaultChainId is selected, the button is not displayed
  if (chainId === null) return null;

  const tokenExplorerLink =
    Number(tokensCount) > 0
      ? getTokenExplorerLinkFilters(chainId, category ? getCategoryID(category) : null)
      : ERoutePath.tokenExplorer;

  const tokenExplorerButtonText =
    category === OPTIONS_OF_TRENDING.uncategorized || category === null
      ? 'Explore More Tokens'
      : tokensCount !== '' && Number(tokensCount) > 0
      ? `Explore ${Number(tokensCount) > 1 ? 'all' : ''} ${tokensCount} ${
          categoryTitle ? `${categoryTitle} ` : ''
        }Token${Number(tokensCount) > 1 ? 's' : ''} ${chainTitle}`
      : 'Explore More Tokens';

  return (
    <Link href={tokenExplorerLink} className={styles.tokenExpButton}>
      <ButtonPrimary size="sm">{tokenExplorerButtonText}</ButtonPrimary>
    </Link>
  );
};

export const EmptyBoxOpenFilterLink = ({ onShowFilters }: { onShowFilters: () => void }) => (
  <ButtonPrimary size="sm" onClick={onShowFilters} className={styles.openFiltersButton}>
    <FontAwesomeIcon icon={faSliders} />
    Adjust Filters
  </ButtonPrimary>
);

export const EmptyBoxSwiperWrapper = ({ children }: React.PropsWithChildren) => (
  <div className={styles.emptyBoxSwiperWrapper}>{children}</div>
);

export const EmptyBoxWithTokens = ({ tokenData }: { tokenData: CoinInfoDto }) => {
  const changePercentOneDay = tokenData.filters.find(
    ({ filterName }) => filterName === CoinFilterDto.filterName.PRICE_PERCENT_CHANGE_USD,
  );
  const oneDay = changePercentOneDay?.timeFrameValues.find((tf) => tf.timeFrame === TimeInterval.OneDay)?.value || 0;
  const formattedChange = utils.format.getFormattedMetric(oneDay, 'percent', true).value;

  const onChainStrengthIndex = tokenData.filters.find(
    ({ filterName }) => filterName === CoinFilterDto.filterName.ON_CHAIN_STRENGTH_INDEX,
  );
  const computedHypeScore = computeHypeScore(onChainStrengthIndex?.timeFrameValues[0]?.value || 0);

  return (
    <Card size="sm" transparent>
      <Flex
        direction={{ xs: 'column-reverse', sm: 'row' }}
        spacing={{ xl: 8, xxl: 16 }}
        wrap={{ xs: 'wrap', sm: 'nowrap' }}
        align="flex-start"
        justify="space-between"
      >
        <div className={styles.infoBox}>
          <Flex align="center" direction="row" spacing={8} justify="flex-start">
            <CoinLogo
              chainId={tokenData.metadata.chainId}
              name={tokenData.metadata.name || tokenData.metadata.symbol || 'N/A'}
              size="small"
              src={tokenData.metadata.logo}
              className={styles.tokenLogo}
            />

            <div className={styles.infoBoxDataTitle}>
              <span className={styles.tokenName} title={tokenData.metadata.name}>
                {tokenData.metadata.name}
              </span>
              <span className={styles.tokenSymbol} title={tokenData.metadata.symbol}>
                {tokenData.metadata.symbol}
              </span>
            </div>
          </Flex>
          <Flex align="center" direction="row" spacing={8} justify="flex-start">
            <span
              className={clsx(styles.changePercent, {
                [styles.positive]: oneDay > 0,
                [styles.negative]: oneDay <= 0,
              })}
            >
              {formattedChange}
            </span>
            <span className={styles.tokenPrice}>{utils.format.formatAsTokenPrice(tokenData.metadata.priceUSD)}</span>
          </Flex>
        </div>
        <div className={styles.infoBoxHypeScore}>
          <span className={styles.infoBoxHypeScoreNumber}>{computedHypeScore}%</span>
          <span
            className={clsx(
              styles.hypeScoreIcon,
              computedHypeScore >= COMPUTED_HYPE_SCORE_MIN ? styles.hypeScorePositive : styles.hypeScoreNegative,
            )}
          >
            <FontAwesomeIcon icon={faFireFlameCurved} width={20} height={20} />
          </span>
        </div>
      </Flex>
    </Card>
  );
};

export const EmptyBoxWithTokensLink = ({ tokenData }: { tokenData: CoinInfoDto }) => {
  return (
    <Link legacyBehavior passHref href={getCoinURLById(tokenData.metadata)}>
      <a className={styles.collectionBox}>
        <EmptyBoxWithTokens tokenData={tokenData} />
      </a>
    </Link>
  );
};
