import { ChainLogo } from '@/components/common/ChainLogo';
import { Constants } from '@/helpers/utils';
import { capitalizeFirstLetter } from '@/helpers/utils/capitalizeFirstLetter';
import { chainOptions } from '@/helpers/utils/chainSelectorOptions';

export const OPTIONS_OF_TRENDING = {
  defaultCategory: 'tokens',
  defaultChainId: 'allChains',
  uncategorized: 'uncategorized',
};

export const createCategoryOptions = (
  categories: {
    id: number;
    value: string;
    name: string;
    type: string;
    parentCategoryId: number | null;
  }[],
) => [
  { id: OPTIONS_OF_TRENDING.defaultCategory, label: 'All Categories' },
  { id: OPTIONS_OF_TRENDING.uncategorized, label: 'Uncategorized' },
  ...categories.map((category) => ({
    id: category.value,
    label: category.name,
  })),
];

export const createChainIDOptions = (uniqueChainIds: string[]) => [
  {
    id: OPTIONS_OF_TRENDING.defaultChainId,
    label: 'All Chains',
    prefix: <ChainLogo chainId="allChains" />,
  },
  ...uniqueChainIds.map((chainID) => {
    const originalChainOption = chainOptions.find((option) => option.id === chainID);

    return {
      id: chainID,
      label: capitalizeFirstLetter(Constants.chainIdToChainNameMappingForTokenPage(chainID) || chainID),
      prefix: originalChainOption ? <ChainLogo chainId={originalChainOption.id} /> : null,
    };
  }),
];
